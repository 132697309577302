import React from 'react'

const PdfViewer = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src={`${process.env.PUBLIC_URL}/display.pdf`}
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="PDF Viewer"
      ></iframe>
    </div>
  )
}

export default PdfViewer